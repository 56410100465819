.container {
  background-color: #000000;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 10;
  height: 30px !important;
  display: flex !important;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;
}

.footerLabel {
  font-family: "SF Pro Medium";
  font-size: 13px;
  line-height: 13px;
  position: relative;
  margin-right: 5px;
  color: white;
  @supports (-webkit-touch-callout: none) {
    letter-spacing: -1px !important;
  }
}
