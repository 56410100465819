$primary-color: #262161;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat-Medium" !important;
  background-color: #e8e6e6 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  background-color: "#e8e6e6" !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7fa3;
  cursor: pointer;
}

@font-face {
  font-family: "Brandon Grotesque Bold";
  src: url("../fonts/BRANDON_BLD.OTF");
}

@font-face {
  font-family: "Brandon Grotesque Outlines";
  src: url("../fonts/BRANDON_BLK.OTF");
}
@font-face {
  font-family: "Brandon Grotesque Light";
  src: url("../fonts/BRANDON_LIGHT.OTF");
}
@font-face {
  font-family: "Brandon Grotesque Medium";
  src: url("../fonts/BRANDON_MED.OTF");
}

@font-face {
  font-family: "Manrope-Bold";
  src: url("../fonts/Manrope-Bold.otf");
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: url("../fonts/Manrope-SemiBold.otf");
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Regular.otf");
}

@font-face {
  font-family: "SF Pro";
  src: url("../fonts/SF-Pro-Text-Regular.otf");
}

@font-face {
  font-family: "SF Pro Medium";
  src: url("../fonts/SF-Pro-Text-Medium.otf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("../fonts/Montserrat-Light.ttf");
}

.MuiInput-underline::before {
  text-decoration: none !important;
  border-bottom: none !important;
}
.MuiInput-underline::after {
  text-decoration: none !important;
  border-bottom: none !important;
}
.MuiCard-root {
  margin: auto;
}
.MuiButton-root {
  text-transform: none !important;
  // background-color: #262161 !important;
  border-radius: 5px !important;
}

input:focus-visible {
  outline: none !important;
}

.underline {
  display: inline-block;
  text-decoration: underline;
  margin-left: 0 5px 0 5px;
}
