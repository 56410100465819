@import "./variables.scss";
$font: "Montserrat-Medium";

.card {
  font-family: "Montserrat-Medium" !important;
  border-radius: 0px !important;
  width: 92.5%;
  margin: auto;
  position: relative;
  top: -5px !important;
  z-index: 0 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-bottom: -6px;
  margin-top: 12px;
  padding-top: 20px;
}

.card1 {
  font-family: "Montserrat-Medium" !important;
  border-radius: 0px !important;
  width: 92%;
  margin: auto;
  position: relative;
  top: -5px !important;
  z-index: 0;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin-bottom: -6px;
}

.card2 {
  font-family: "Montserrat-Medium" !important;
  border-radius: 0px !important;
  width: 92%;
  margin: auto;
  position: relative;
  top: -5px !important;
  z-index: 0 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  margin-bottom: 20px;
  margin-top: -3.2px;
  overflow: visible !important;
}

.timeGap {
  display: inline-block;
  height: 4px;
  width: 4px;
  background-color: #5e587380;
  border-radius: 50%;
  position: relative;
  bottom: 1px;
  //margin-top: 3px;
}

.line {
  width: 100% !important;
  border-bottom: 1px solid #e8e6e6;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
}

.arrowContainer {
  position: relative;
  margin-right: 22px;
  margin-left: auto;
  width: 10.72px;
  margin-top: -1px;
}

.arrowContainer1 {
  position: relative;
  margin-right: 26px;
  margin-left: auto;
  width: 10.72px;
  margin-top: -1px;

  margin-bottom: -3px;
}

.arrow {
  transition: 0.3s transform ease-in-out;
}
.arrowCollapsed {
  transition: 0.3s transform ease-in-out;
  transform: rotate(180deg);
}

.container {
  background-color: #e8e6e6 !important;
  height: 70vh;
  min-height: 70vh;
}

.BackArrowCont {
  padding: 14px 0 8px 0;
  width: 92%;
  margin: auto;
}

.headerLogoContainer {
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
}

.label {
  text-align: center;
  font-size: 16px;
}

.imageContainer {
  width: 50%;
}

.image {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 50px;
}

.button {
  font-family: "Montserrat-Medium" !important;
  width: 100%;
  margin: auto;
  padding: 10px 0 10px 15px !important;
  font-size: 14px !important;
  z-index: 1;
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;

  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.buttonContainer {
  font-family: "Montserrat-Medium" !important;
  position: relative;
  text-align: center;
  margin: auto;
  width: 92%;
}
.buttonAndCardWrapper {
  margin-bottom: 16px;
}

.billIconContainer {
  position: relative;
  right: 7px !important;
  top: 4px !important;
}

.splitIconContainer {
  position: relative;
  right: 13px !important;
  top: 3px !important;
}
.billDataContainer {
  padding: 10px 16px;
  padding-bottom: 4px;
  width: 100% !important;
  margin: 0 auto;
  border-bottom: 1px dashed #ebe9f1;
}
.leftContainer {
  text-align: left;
  font-size: 12px !important;
  font-family: "Montserrat-Medium" !important;
  color: #5e5873b3 !important;
}
.inleftCont {
  text-align: right;
  font-size: 12px;
  font-family: "Montserrat-Medium" !important;
  color: #5e5873 !important;
}
.rightContainer {
  font-size: 12px !important;
  text-align: left !important;
  font-family: "Montserrat-Medium" !important;
  color: #5e5873b3 !important;
}
.inRightCont {
  text-align: right;
  font-size: 12px;
  font-family: "Montserrat-Medium" !important;
  color: #5e5873 !important;
}

.trn {
  margin-top: -18px;
  padding: 0 17px 12px 17px;
  font-family: $font-medium !important;
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;
}
.trnText {
  color: #5e5873b3 !important;
}
.trnID {
  color: #5e5873 !important;
}

.tableWrapper {
  margin-top: 18px;
  margin-bottom: 20px;
  padding-bottom: 0px !important;
  font-family: "Montserrat-Medium" !important;
}

.tableHeaderCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-family: "Montserrat-Medium" !important;
}

.tableItemHeaderCell {
  font-family: "Montserrat-Medium" !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableRowCell {
  font-family: "Montserrat-Medium" !important;

  padding: 5px 16px 10px 10px !important;
  font-size: 12px !important;
  border-bottom: none !important;
  color: #5e5873 !important;
  border-bottom: none !important;
  text-align: left !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  background-color: #ffb3c726;
}

.tableRowCell2 {
  font-family: "Montserrat-Medium" !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  font-size: 12px !important;
  color: #5e5873b3 !important;

  border-bottom: none !important;
}

.midCell {
  border-bottom: none !important;
}

.tableRowCellPay {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 17px !important;
  background-color: #fff !important;
  border-bottom: none !important;
}

.tableRowCell3 {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 12px !important;

  border-bottom: none !important;
}

.tableRowCellRight {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  font-size: 12px !important;
  min-width: 85px;
}

.tableRowCellRight2 {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  font-size: 10px !important;
  min-width: 85px;
}

.tableRowItemCell {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableBottomCell {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 16px !important;
}

.tooltipCont {
  display: inline-block;
  width: 27% !important;
  text-align: center !important;
  @supports not (-webkit-touch-callout: none) {
    transform: translateX(-12%);
  }
}

.payAm {
  transform: translateX(-8%);
  text-align: right !important;
}

.totalRow {
  font-size: 16px;
  padding-bottom: 207px !important;
  border-bottom: none !important;
}

.payerRowCell {
  font-family: "Montserrat-Medium" !important;
  padding: 10px 16px 10px 20px !important;
  font-size: 12px;
  border-bottom: none !important;
  color: #5e5873;
}

.payerRowCell2 {
  font-family: "Montserrat-Medium" !important;
  padding: 3px 16px 0px 10px !important;
  font-size: 12px;
  border-bottom: none !important;
  color: #5e5873;
  text-align: left !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;

  margin-top: 4.5px;
}

.payerRowCellUser {
  font-family: "Montserrat-Medium" !important;
  padding: 5px 0 5px 18px !important;
  font-size: 13px !important;

  border-bottom: none !important;
  background-color: #ffb3c726;
}

.totalPaymentRow {
  background-color: #d9d9d9;
  margin-bottom: 20px !important;
  font-size: 16px !important;
}

.totalPaymentRowCell {
  font-family: "Brandon Grotesque Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 16px !important;
}

.tableBottomCellExtend {
  font-family: "Brandon Grotesque Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 16px !important;
  width: 50%;
  border-bottom: none !important;
}

.table0 {
  font-family: "Montserrat-Medium" !important;
  margin-top: -6px !important;
}

.table {
  font-family: "Montserrat-Medium" !important;
}
.tableBillItem {
  display: flex !important;
}
.tableHeaderCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-family: "Montserrat-Medium" !important;
  font-size: 12px !important;
}

.tableItemHeaderCell {
  font-family: "Montserrat-Medium" !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableRowCellRight {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  font-size: 12px !important;
  min-width: 85px;
  border-bottom: none !important;
  color: #5e5873 !important;
  letter-spacing: 0.2px !important;
}
.tableRowItemCell {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableBottomCell {
  font-family: "Montserrat-Medium" !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: none !important;
  font-size: 16px !important;
  padding-right: 0px !important;
}

.tableBottomCellRight {
  font-family: "Montserrat-Medium" !important;
  border-bottom: none !important;
  font-size: 16px !important;
  padding-left: 0px !important;
  font-weight: bold !important;
  color: black !important;
}

.tableTotalAmountCell {
  font-family: "Montserrat-Medium" !important;
  border-bottom: none !important;
  font-size: 16px !important;
  font-weight: bolder !important;
  padding-right: 0px !important;
}
.totCont {
  background-color: rgb(255, 255, 255) !important;
  width: 92%;
  margin: auto;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.totalRow {
  font-size: 12px;
  padding-bottom: 207px !important;
}

.fees {
  position: relative;
  font-family: "Montserrat-Regular" !important;
  width: 140%;
  font-size: 12px !important;
  color: #5e5873b3;
  left: 18px;
  bottom: 25px;
}
.table {
  border-bottom: none !important;
}

.tableRowCellBill {
  font-family: $font !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-right: 0px !important;
  font-size: 12px !important;
  border-bottom: none !important;
  color: #5e5873;
  text-transform: capitalize !important;

  width: 100% !important;
}

.itemQty {
  display: inline-block;
  padding-right: 8px;
  color: #5e5873 !important;
}
.itemSpacer {
  display: inline-block;
  color: #5e5873b3 !important;
  font-size: 12px;
  width: 90% !important;
  word-wrap: break-word !important;
}
.billItemDis {
  padding-left: 20px;
  padding-top: 5px;
  color: #5e5873b3 !important;
  font-size: 11px !important;
  font-family: "Montserrat-Light" !important;
}
.itemRealAmount {
  text-decoration: line-through !important;
}
.tableRowCellBillDiscount {
  font-family: "SF Pro Medium" !important;
  font-size: 12px;
}
.tableRowCellRightDiscount {
  color: #1dc369 !important;
  text-align: right !important;
  font-family: "SF Pro Medium" !important;
  font-size: 12px;
}
.disclaimer {
  color: $font-gray-light;
  padding: 0 16px 10px 16px;
  font-size: 11px;
  font-family: $font-regular;
}

.detailsRow {
  font-family: "Montserrat-Medium" !important;
  width: 100%;
  margin: auto;
  padding: 10px 15px !important;
  font-size: 14px !important;
  z-index: 1;
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.totalItemLevelDiscounts {
  font-family: $font !important;
  font-size: 12px !important;
  color: #5e5873 !important;
}

.totalItemDiscountsCell {
  font-family: "SF Pro Medium" !important;
  font-size: 12px !important;
  color: #1dc369 !important;
  text-align: right !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  border-bottom: none !important;
  letter-spacing: 0.2px !important;
}
