.container {
  background-color: #fff;
  width: 32px;
  border-radius: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .icon {
    background-color: #fff;
    height: 16px;
    width: 16px;
  }
  border-radius: 10px;
  box-shadow: 0px 3px 10px #26216178;
}
