@import "./variables.scss";

$hero-color: #262161;
$font: "Montserrat-Medium";

.button_active {
  background-color: #7bed9f !important;
}

.button_active:hover {
  background-color: #7bed9f !important;
}

.card {
  font-family: $font !important;
  border-radius: 0 !important;
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #e8e6e6;
}

.card2 {
  font-family: $font-medium;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  position: relative;
  padding: 14px;
}

.tipContainer {
  font-family: $font !important;
  border-radius: 6px 6px 0 0 !important;
  z-index: 0;
  padding: 10px 15px 10px 15px;
  margin-bottom: 2px;
}

.payContainer {
  font-family: $font !important;
  border-radius: 0 0px 5px 5px !important;
  z-index: 0;
  padding: 5px 15px 20px 15px;
}

.label {
  text-align: center;
  font-size: 16px;
}

.imageContainer {
  width: 50%;
}

.image {
  text-align: center;
  padding-top: 27px;
  padding-bottom: 25px;
}

.button {
  font-family: $font !important;
  width: 100%;
  margin: auto;
  padding: 10px 0 10px 15px !important;
  font-size: 14px !important;
  z-index: 1;
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  height: 47.2px !important;
}

.line {
  width: 100% !important;
  border-bottom: 1px solid #e8e6e6;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
}

.line2 {
  position: absolute;
  border-bottom: 1px solid #e8e6e6;
  top: 1px;
  left: -5%;
  width: 111% !important;
}

.line3 {
  position: absolute;
  border-bottom: 1px solid #e8e6e6;
  top: 0;
  left: -8%;
  width: 100vw !important;
}

.line4 {
  position: absolute;
  border-bottom: 1px solid #e8e6e6;
  bottom: 0;
  left: -8%;
  width: 100vw !important;
}

.line5 {
  width: 100vw !important;
  height: 10px;
}

.button2 {
  font-family: $font !important;
  margin: auto;
  font-size: 12px !important;
  text-decoration: underline;
  background-color: #fff !important;
  display: flex;
  justify-content: center;
  color: #5e5873 !important;
}

.button3 {
  font-family: $font !important;
  width: 100%;
  margin: auto;
  padding: 10px 0 4px 15px !important;
  font-size: 14px !important;
  z-index: 1;
  background-color: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

.button4 {
  font-family: $font !important;
  width: 100%;
  margin: auto;
  padding: 10px 0 10px 0px !important;
  font-size: 14px !important;
  z-index: 1;
  background-color: #5e5873 !important;
  color: rgb(0, 0, 0) !important;
  letter-spacing: 0.6px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  color: "#fff" !important;
  position: relative;
}

.buttonContainer {
  font-family: $font !important;
  position: relative;
  text-align: center;
  margin: auto;
}
.buttonAndCardWrapper {
  margin-bottom: 16px;
}

.billIconContainer {
  position: relative;
  right: 7px !important;
  top: 4px !important;
  display: inline-block;
  margin-left: 80px;
}

.splitIconContainer {
  margin-left: 80px;
  position: relative;
  right: 13px !important;
  top: 3px !important;
  display: inline-block;
}
.banter {
  font-family: $font !important;
  text-align: left !important;
  background-color: red;
  margin-left: 0;
}
.tipsIconContainer {
  margin-left: 90px;
  position: relative;
  right: 13px !important;
  top: 3px !important;
  display: inline-block;
}

.billDataContainer {
  margin-top: 20px !important;
}

.leftContainer {
  padding-left: 14px;
  font-size: 14px !important;
  text-align: left;
}
.rightContainer {
  padding-right: 16px;
  font-size: 14px !important;
  text-align: right;
}

//table related styles
.table {
  font-family: $font !important;
}

.tableHeaderCell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-family: $font !important;
  font-size: 12px !important;
}

.tableItemHeaderCell {
  font-family: $font !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableHeaderRow {
  border-top: 1px solid #00000050 !important;
}

.tableRowCell {
  font-family: $font !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 0px !important;
  font-size: 12px !important;
  border-bottom: none !important;
  color: #5e5873;
  text-transform: capitalize !important;
}

.tableRowCellRight {
  font-family: $font !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  font-size: 12px !important;
  min-width: 85px;
  border-bottom: none !important;
  color: #5e5873 !important;
  letter-spacing: 0.2px !important;
}
.tableRowCellBillDiscount {
  font-family: "SF Pro Medium" !important;
  color: #5e5873 !important;
  font-size: 12px !important;
}

.tableRowCellRightDiscount {
  font-family: "SF Pro Medium" !important;
  font-size: 12px !important;
  color: #1dc369 !important;
  text-align: right !important;
}
.tableRowItemCell {
  font-family: $font !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tableBottomCell {
  font-family: $font !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-bottom: none !important;
  font-size: 16px !important;
  padding-right: 0px !important;
}

.totCont {
  background-color: rgb(255, 255, 255) !important;
  margin: auto;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  padding: 15px 15px;
}

.totalRow {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 5px;
}

.totalValue {
  text-align: end;
}

.fees {
  font-family: "Montserrat-Regular";
  font-size: 12px;
  color: #5e5873b3;
}
.table {
  border-bottom: none !important;
}

.tableRowCellBill {
  font-family: $font !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding-right: 0px !important;
  font-size: 12px !important;
  border-bottom: none !important;
  color: #5e5873;
  text-transform: capitalize !important;

  width: 100% !important;
}
.tableBillItem {
  display: flex !important;
  align-items: center !important;
}
.itemRealAmount {
  text-decoration: line-through !important;
}
.billItemDis {
  padding-left: 20px;
  padding-top: 5px;
  color: #1dc369 !important;
  font-size: 11px !important;
  font-family: "Montserrat-Light" !important;
}
.itemQty {
  display: inline-block;
  padding-right: 8px;
  color: #5e5873 !important;
}
.itemSpacer {
  display: inline-block;
  color: #5e5873b3 !important;
  font-size: 12px;
  width: 90% !important;
  word-wrap: break-word !important;
}

// Split your bill
.yourPartyText {
  font-size: 12px !important;
  padding-left: 0px !important;
  color: #5e5873b3;
  margin-bottom: 10px;

  position: relative;
  top: 4px;
}
.payerRow {
  font-size: 15px !important;
  padding-top: 13px !important;
}

.nameInputNumberContainer {
  width: 15%;
  color: #00000040;
  text-align: center;
}

.payStatus {
  width: 25%;
  font-size: 10px;
  font-family: $font !important;
}

.nameInputContainer {
  margin-top: 30px;
  color: #00000040;
}

.deleteButton {
  width: 20%;
  text-align: center;
}

.remainingAmountText {
  font-size: 12px !important;
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
  margin-top: 12.5px !important;
  position: relative;
  color: #5e5873 !important;
}

.remainingAmountValue {
  font-size: 12px !important;
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
  padding-right: 6px !important;
  margin-top: 12.5px !important;
  text-align: right !important;
  color: #5e5873 !important;
}

.shareIcon {
  transform: rotate(0deg);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  font-size: 18px !important;
  color: #5e5873 !important;
}

.GuestInp {
  padding: 3rem !important;
}

.sendBillbuttonContainer {
  font-family: $font-medium;
  text-align: center;
  margin: auto;
}
.sendBillIconContainer {
  position: relative;
  right: 10px !important;
  top: 1px !important;
}

// Tips and payment
.addTipText {
  font-size: 15px !important;
  padding-left: 6% !important;
  margin-top: 10px !important;
  padding-bottom: 10px !important;
  display: inline-block;
}

.addTipWarningImage {
  display: inline-block;
  position: relative;
  top: 2px;
  right: 8px;
}

.tipSpacer {
  display: inline-block;
  position: relative;
  top: 5px;
}

.myTotalPaymentRow {
  width: 100%;
  font-size: 16px !important;
  font-family: $font !important;
}
.myTotalPaymentLabel {
  padding: 10px 0px 2px 0px;
  font-size: 14px;
  background-color: #ffffff;
  font-family: $font !important;
  font-weight: bold;
  border: none !important;
  outline: none !important;
}
.myTotalPaymentValue {
  padding: 10px 0px 2px 10px;
  text-align: right;
  background-color: #ffffff;
  font-family: $font !important;
  font-weight: bold;
  font-size: 16px;
  border: none !important;
  outline: none !important;
}

.myTotalPaymentValue2 {
  padding: 12px 0 10px 10px;
  text-align: right;
  font-size: 13.5px;
  font-family: $font !important;
  font-weight: bold;
}
.tipInPay {
  font-family: "Montserrat-Regular" !important;
  font-size: 12px;
  color: rgb(158, 158, 158) !important;
  background-color: #fff;
  gap: 5px;
  display: flex;
}

.payHero {
  font-size: 12px !important;
  color: "#5E5873" !important;
  font-family: $font !important;
  padding: 10px 0;
}

.paybuttonContainer {
  font-family: $font !important;
  text-align: center;
}

//button Text
.buttonTextContainer {
  text-align: left;
  margin-left: 3px;
  display: inline-block;
  margin-left: 4px;
}

//arrrow animation
.arrow {
  transition: 0.3s transform ease-in-out;
}
.arrowCollapsed {
  transition: 0.3s transform ease-in-out;
  transform: rotate(180deg);
}

.arrowContainer {
  position: relative;
  margin-right: 22px;
  margin-left: auto;
  width: 10.72px;
  margin-top: -1px;
}

.arrowContainer1 {
  position: relative;
  margin-right: 26px;
  margin-left: auto;
  width: 10.72px;
  margin-top: -1px;

  margin-bottom: -3px;
}

.arrowContainerWrapped {
  position: relative;
  margin-right: 8px;
}

//card payment
.applePayIconContainer {
  position: relative;
  right: 7px !important;
  top: 5px;
  display: inline-block;
}

.cardPayIconContainer {
  position: absolute;
  background-color: #fff;
  display: flex;
  padding: 3.5px 7px;
  box-sizing: border-box;
  border-radius: 8px;
  left: 7%;
}

.cardName {
  color: #fff;
  font-size: 16px;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  margin-right: -10px;
}
.cardPaymentMethodIconContainer {
  position: relative;
  left: 10% !important;
  display: inline-block;
}

.cardElementContainer {
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  border: solid 1px $border-color;
  border-radius: $border-radius;
}

.visaCard {
  font-family: "Montserrat-Regular" !important;
  background-color: #fff !important;
  border: none !important;
  width: 100%;
  margin: auto;
  position: relative;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding-top: 20px !important;
}

.input {
  background-color: #e4e4e3 !important;
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 32px;
  width: 90%;
}

.userNameInp {
  outline: none !important;
  border: none;
}

.inputContainerShort {
  display: inline-block;
  width: 90%;
  height: 60%;
}

.cardDetailText {
  position: relative;
  bottom: 70%;
  display: inline-block;
  font-family: "Montserrat-Regular" !important;
}

.userConditionsText {
  font-size: 11px !important;
  color: #5e5873bf !important;
}

.userConditions {
  display: inline-block;
  text-decoration: underline;
  margin-left: 5px;
  font-size: 11px !important;
  color: #5e5873bf !important;
  font-family: "Montserrat-Regular" !important;
}

.payButton {
  font-family: $font !important;
  width: 90%;
  border-radius: 10px !important;
  margin: auto;
  padding: 10px 0 10px 0 !important;
  font-size: 17px !important;
  z-index: 1;
}

.customTip {
  width: 80%;
  border: 0;
  text-align: center !important;
  margin: auto;
  display: block;
  padding: 1px 0;
  outline: none !important;
  font-size: 16px !important;
  font-family: $font-medium;
  background: none;
}

.customTip::placeholder {
  font-family: $font-regular;
}
.myPaymentInput::placeholder {
  font-family: $font-regular;
}

.popupContainer {
  text-align: right;
  margin-right: 10px;
}

.closeButton {
  background-color: red !important;
  color: white !important;
}

.test {
  background-color: red !important ;
}
.test:hover {
  background-color: blue !important ;
}
.currencySpan {
  font-size: 12px !important;
  padding: 0px;
  margin-top: 14px;
  display: inline-block;
  margin: 0px !important;
}

.userConditionsText {
  text-align: left !important;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 12px;
  cursor: default;
}
.userConditions {
  text-decoration: underline;
  display: inline-block;
  cursor: text !important;
}

.buttonContainer {
  text-align: left;
}
.buttonContainerWithNoMargin {
  width: 99%;
  text-align: left;
  margin: auto;
}

.spacer {
  height: 43px;
}

.tips {
  display: inline-block;
  background-color: chartreuse;
}

.tipDialog {
  padding: 20px 15px;
  position: relative;
}

.tipDialogPaper {
  border-radius: 6px !important;
  box-shadow: none !important;
  margin: 15px !important;
}

.tipDialogCloseButton {
  position: absolute;
  top: 15px;
  right: 10px;
  height: 32px;
  width: 32px;
  border: solid 1px #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 10px;
}

.topRow {
  position: relative;
  margin: 10px 0px 40px 0px;
}

.backWrapper {
  position: absolute;
}

.headerOuter {
  text-align: center;
}

.headerText1 {
  font-size: 16px;
}

.headerText2 {
  font-size: 17px;
  font-weight: bold;
}

.static_outer {
  position: fixed;
  bottom: 44px;
  left: 0;
  width: 100%;
}

.static_button_inner {
  margin: auto;
  max-width: 800px;
  padding: 0px 16px;
}

.totalItemDiscountsCell {
  font-family: "SF Pro Medium" !important;
  font-size: 12px !important;
  color: #1dc369 !important;
  text-align: right !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  border-bottom: none !important;
  letter-spacing: 0.2px !important;
}

@media only screen and (max-width: 340px) {
  .buttonTextContainer {
    text-align: left !important;
    margin-left: 2px !important;
    display: inline-block !important;
    font-size: 14px !important;
  }
  .banter {
    display: inline-block;
    font-family: $font !important;
    font-size: 13.5px !important;
  }

  .remainingAmountValue,
  .remainingAmountText {
    font-family: $font !important;
    font-size: 12px !important;
    color: #5e5873 !important;
  }
  .cardName {
    font-size: 13px !important;
  }
  .totalRow {
    font-size: 14px;
  }
}

.fabIcon {
  // position: fixed;
  float: right;
}
