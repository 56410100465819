@import "./variables.scss";

.imageContainer {
  width: 50%;
}

.image {
  text-align: center;
  padding-top: 20px;
  display: block;
  margin: auto;
}
.wrapper {
  width: 92%;
  margin: auto;
  padding: 0;
}

.topbBar {
  background-color: $primary-color;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  border-radius: $border-radius;
  margin: auto;
  margin-top: 20px;
}

.topbBarColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.topbBarMiddleColumn {
  width: 100%;
}
.list {
  width: 327px;
  height: 100%;
  padding-top: 20px;
}

.bergerMenuIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.userName {
  font-size: 16px;
  font-family: "Montserrat-Medium";
  margin-left: 28px;
  color: #5e5873;
}
.userEmail {
  font-size: 14px;
  font-family: "Montserrat-Regular";
  margin-left: 28px;
  color: #5e5873;
  margin-bottom: 28px;
  width: 100px;
  line-height: 1.5em !important;
}
.closeIconContainer {
  text-align: right;
  // margin-top: 32.51px;
  margin-right: 20px;
  // margin-bottom: 30px;
}

.orderHistryHeader {
  padding-top: 13px;
  padding-left: 20px;
  font-size: 20px;
  font-family: "SemiBold";
}

.navbarImage {
  margin-right: 10px;
  position: relative;
  top: 2px;
  margin-left: 10px;
}

.navBarText {
  font-family: "Montserrat-Medium";
  font-size: 15px;
  margin-left: 10px;
  color: #5e5873 !important;
}

.profileImageWrappper {
  text-align: right;
  display: flex;
}

.profileImage {
  border-radius: 20px;
  background-color: white;
}
.drawerWrapper {
  z-index: -7 !important;
}

.listItem {
  font-family: "Manrope-SemiBold" !important;
}

@media (orientation: landscape) {
  .linkContainer {
    overflow: auto !important;
    height: 245px;
  }
}

.navLogoContainer {
  // margin-top: 32.5px;
  // margin-left: 28px;
  margin-bottom: 29.77px;
}

.navRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  padding: 0px 10px;
  align-items: center;
}
