@import "./variables.scss";

.wrapper {
  padding: 0 16px;
  width: 100%;

  @media (min-width: 801px) {
    width: 90%;
    margin: auto;
    padding: 0;
  }
}

.mainContainer {
  background-color: $primary-color;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  border-radius: $border-radius;
  margin: auto;
  margin-top: 20px;
}
