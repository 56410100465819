@import "./variables.scss";

.container {
  height: 100vh;
  background-color: #fff;
  padding-top: 20%;
}

.innerConntainer {
  max-width: 500px !important;
}

.backgroundImageContainer {
  z-index: -4;
  overflow: hidden;
  background-color: black;
}

.restaurentLogoContainer {
  text-align: center;
  margin: auto;
  margin-top: 20px;
  z-index: 4;
  width: 100% !important;
}

.restaurantNameContainer {
  font-size: 20px !important;
  color: "#828282" !important;
  // font-family: $font-semibold;
  margin-bottom: 50px;
  opacity: "0.5";
  font-weight: 500;
  cursor: default;
}

.logoDim {
  max-width: 150px;
  max-height: 170px;
}
.tableNumberContainer {
  position: relative;
  font-family: $font-regular;
  text-align: center;
  font-size: 21px;
  cursor: default;
}

.tableName {
  font-size: 12px !important;
  color: "#5E5873" !important;
  font-family: "Montserrat-Regular" !important;
}
.tableChild {
  font-size: 20px !important;
  color: "#5E5873" !important;
  font-family: "Montserrat-SemiBold" !important;
}

.linkCont {
  text-align: center;
  justify-content: center;
  text-decoration: underline;
  color: #717074;
  font-size: 12px;
  padding-top: 0.4rem;
  font-family: $font-medium;
  letter-spacing: 0.525px;
  line-height: 1.43;
  font-weight: normal;
}

.viewMenuButton {
  background-color: #fff !important;
  border: solid 1.5px #262161 !important;
}

.buttonSet {
  padding: 26px 20px 0 20px;
  text-align: center;
}
.moreInfo {
  text-decoration: underline;
  color: #5e5873;
  font-family: $font-semibold;
  font-size: 14px;
  margin-top: 15px;
  cursor: pointer;
}

.maintenanceMessage {
  padding: 20px 0 0;
  color: $font-gray-light;
  max-width: 300px;
  margin: auto;
}

@media (min-width: 640px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }
}
