.OrCont{
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineAlt{
  width: 43%;
  height: 1px;
  background-color: #0000001D !important;
}
.midText{
  margin: 0 5px;
  color: #5E587380 !important;
}