@import "./variables.scss";

.card {
  padding: 10px 25px;
  margin-top: 58px;
  height: 100%;
}

.label {
  text-align: left;
  font-size: 12px;
  font-family: $font-regular;
  color: $font-gray-medium;
  margin-bottom: 5px;
}

.imageContainer {
  width: 50%;
}

.arrowContainer {
  padding-bottom: 70px;
}

.hideBackgroundImage {
  visibility: hidden;
}
