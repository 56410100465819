@import "./variables.scss";

.banner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  font-family: "Manrope";
  padding: 10px 20px;
}

.bannerText {
  display: inline-block;
  position: relative;
}

.greenTick {
  text-align: center;
  padding-right: 10px;
  display: inline-block;
}

.profileHeaderContainer {
  font-family: $font-medium;
  font-size: 15px;
  text-align: start;
  margin-bottom: 12px;
  padding-left: 14px;
}

.label {
  text-align: center;
  font-size: 16px;
}

.inputContainer {
  padding-top: 14px;
  margin: auto;
  text-align: center;
  // @supports (-webkit-touch-callout: none) {
  //   line-height: 15px !important;
  // }
}

.userName {
  font-size: 16px;
  margin-top: 16px;
  font-family: "Montserrat-Medium";
  text-align: center;
  color: #5e5873;
}
.userEmail {
  font-size: 12px;
  font-family: "Montserrat-Medium";
  text-align: center;
  padding-bottom: 17px;
  border-bottom: 1px #00000013 solid;
  margin-bottom: 20px;
  width: 85%;
  margin: auto;
  color: #5e5873;
}

.fieldLabel {
  font-family: $font-regular;
  font-size: 12px;
  display: inline-block;
  color: $font-gray-medium;
  margin-bottom: 8px;
}

.mobileInputContainer {
  margin: auto;
  text-align: center;
  @supports (-webkit-touch-callout: none) {
    line-height: 15px !important;
  }
}

//profile image related
.imageContainer {
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  overflow: hidden;
  height: 70px;
  width: 70px;
  margin-top: 28px;
  .imageInput {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.image {
  border-radius: 35px;
  object-fit: contain;
}

.avatarImage {
  margin: auto;
  height: 71.5px;
  width: 71.5px;
}

.mobileContainer {
  width: 100% !important;
  margin: auto;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid #5e587330;
  border-radius: 6px;
}
.mobileInputClass {
  margin-left: 1px;
  margin: auto;
  width: 99% !important;
  border: none !important;
}
.mobileDropdownClass {
  text-align: left;
  width: 68vw !important;
  position: fixed !important;
  border: none !important;
}
.mobileButtonClass {
  background-color: rgb(255, 255, 255) !important;
  border: none !important;
  border-right: 1px solid #5e587330 !important;

  position: relative;
  left: 1%;
}
