.card {
  background-color: #ffffff !important;
  border-radius: 6px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 90%;
  margin: auto;
  padding-bottom: 15px;
}
.container {
  background-color: #e8e6e6 !important;
  height: 100vh;
  min-height: 100vh;
}

.label {
  text-align: center;
  font-size: 15px;
  margin-bottom: 15px;
}

.button {
  width: 95% !important;
  border-radius: 7px !important;
  display: block;
  color: black !important;
  background-color: #9aefcb !important;
  font-family: "Manrope-Bold" !important;
  font-weight: 600 !important;
  padding: 8px 0 8px 0 !important;
  margin: 24px 0 20px 0 !important;
  font-size: 11px !important;
  height: 30px;
}
.buttonDisabled {
  width: 95% !important;
  border-radius: 7px !important;
  display: block;
  color: rgba(0, 0, 0, 0.267) !important;
  background-color: #9aefcb40 !important;
  font-family: "Manrope-Bold" !important;
  font-weight: 600 !important;
  padding: 10px 0 10px 0 !important;
  margin: 24px 0 20px 0 !important;
  height: 30px;
  font-size: 11px !important;
}

.orderHistryHeader {
  margin: 0px 0 12px 13px;
  margin-left: 16px !important;
  font-size: 15px;
  color: #5e5873;
  font-family: "Montserrat-Medium";
}

.emptyOrdersTitle {
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  color: #5e5873;
  font-family: "Montserrat-Medium";
}


.cardHeaderImage {
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 4px;
}

.orderCard {
  border-bottom: 1px #00000013 solid;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.arrowContainer {
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

.rightContainer{
  display: flex;
  align-items: center;

}

.logoContainer {
  text-align: right;
  max-height: 50px !important;
  overflow: hidden;
}

.textContainer{
  display: flex;
  flex-direction: column;
}

.priceContainer {
  font-family: "Montserrat-SemiBold";
  color: #5e5873;
  font-size: 15px;
  font-weight: 400;
}

.dateContainer {
  font-family: "Montserrat-Medium";
  font-size: 12px;
  color: #5e587380;
  margin-top: 6px;
}

.modalHeader {
  text-align: center;
  font-family: "Manrope-Bold";
  font-size: 16px;
}

.modalSubHeader {
  margin-top: 16px;
  text-align: center;
  font-family: "Manrope-SemiBold";
  font-size: 12px;
  margin-bottom: 10px;
}

.emojiContainer {
  text-align: center;
  margin-bottom: 10px;
  height: 70px;
}

.emojiImage {
  height: 40px;
  transition: height 0.3s;
}

.emojiImageSelected {
  height: 50px;
}

.feedBackOptionHeader {
  text-align: center;
  margin-bottom: 15px;
  font-size: 12px;
  font-family: "Manrope" !important;
  font-weight: 500;
}

.feedBackButton {
  font-size: 11px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: "Brandon Grotesque Medium" !important;
  margin: auto !important;
  margin: 5px !important;
  text-align: center !important;
  text-transform: none !important;
}

.feedBackInputContainer {
  overflow: hidden;
}
.feedBackInput {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  font-family: "Manrope";
  border-radius: 10px;
  padding-left: 12px;
  padding-top: 3px;
  padding-right: 10px;
  height: 83px;
  width: 95%;
  font-size: 15px;
  font-weight: 500;
}
.feedBackInput:focus {
  outline: none;
}

.feedBackInput::placeholder {
  font-size: 12px;
}

.feedBackIconContainer {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 13px;
}

.thankYouTextHeader {
  text-align: center;
  font-size: 19px;
}

.thankYouTextSubHeader {
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  color: #000000b1;
}

.modalCloseButton {
  position: relative;
  left: 92.5%;
}
.submitButton {
  width: 95% !important;
  border-radius: 15px !important;
  display: block;
  color: black !important;
  background-color: #9aefcb !important;
  font-family: "Manrope-Bold" !important;
  font-weight: 600 !important;
  padding: 8px 0 8px 0 !important;
  margin: 5px 0 0 0 !important;
  font-size: 17px !important;
  height: 50px;
}

.submitButtonDisabled {
  width: 95% !important;
  border-radius: 15px !important;
  display: block;
  color: rgba(0, 0, 0, 0.267) !important;
  background-color: #9aefcb40 !important;
  font-family: "Manrope-Bold" !important;
  font-weight: 600 !important;
  padding: 8px 0 8px 0 !important;
  margin: 5px 0 0 0 !important;
  font-size: 17px !important;
  height: 50px;
}

.loading{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.doneButtonContainer {
  text-align: center;
}

@media (orientation: landscape) {
  .modalStyles {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    position: absolute;
    overflow: scroll;
    left: 10%;
    display: block;
  }
  .emojiContainer {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .priceContainer {
    font-family: "Manrope-Bold";
    font-size: 11.5px;
    display: inline-block;
    font-weight: 400;
  }

  .dateContainer {
    font-family: "Manrope";
    font-size: 11.5px;
    display: inline-block;
    font-weight: 500;
  }
}
