.card {
  padding: 0px !important;
  background-color: #ffffff !important;
  border-radius: 6px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 92.5%;
  margin: auto;
  top: -10px;
  margin-bottom: 10px;
  position: relative;
}
.topTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 84px;
}
.thankingText {
  color: #5e5873;
  font-size: 13px;
  font-family: "Montserrat-Medium";
}
.successText {
  color: #5e5873b3;
  font-size: 11px;
  font-family: "Montserrat-Medium";
  padding: 1px 0;
}
.amountText {
  font-size: 12px;
  font-family: "Montserrat-Medium";
}
.girdContainer {
  border-bottom: 1px solid #ebe9f1;
}

.divider {
  position: relative;
  height: 1px;
  background-color: #ebe9f1;
  width:150%;
  left: -50px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.remaingBillText {
  text-align: left;
  display: inline-block;
  color: #5e5873;
  font-size: 14px;
}
.remaingBillAmountText {
  text-align: right;
  display: inline-block;
  color: #5e5873;
}

.tickmageWrapper {
  border: 2px solid #1dc369;
  height: 26px;
  width: 26px;
  margin: auto;
  border-radius: 15px;
}

.container {
  background-color: #e8e6e6 !important;
  height: 100vh;
  min-height: 100vh;
}

.label {
  text-align: left;
  font-size: 14px;
  font-family: "Montserrat-Medium";
  margin-bottom: 16px;
}

.imageContainer {
  width: 50%;
}

.image {
  text-align: center;
  padding-top: 30.67px;
  padding-bottom: 40px;
}

.inputContainer {
  padding-top: 14px;
  margin: auto;
  text-align: center;
}
.input {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 32px;
}
.button {
  width: 100%;
  height: 47px;
  margin: auto;
  font-family: "Montserrat-Medium" !important;
  font-size: 14px !important;
  background-color: #262161 !important;
  color: black !important;
  border-radius: 5px !important;
}
.OrCont{
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginSignup{
  color: #5E5873;
  text-align: center;
  text-decoration: underline;
  font-family: "Montserrat-SemiBold" !important;
  font-size: 13px !important;
}
.lineAlt{
  width: 43%;
  height: 1px;
  background-color: #0000001D !important;
}
.midText{
  margin: 0 5px;
  color: #5E587380 !important;
}
.submitButton {
  width: 99%;
  height: 53px;
  margin: auto;
  font-family: "Montserrat-Medium" !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  color: black !important;
}

.submitButton:disabled {
  background-color: #5e587310 !important;
  color: #00000030 !important;
}

.buttonContainer {
  position: relative;
  text-align: center;
  margin: auto;
  width: 93%;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.waitingLogo {
  padding-top: 23px;
  height: 50px;
  margin: auto;
  text-align: center;
  display: block;
}

.paymentHeaderText {
  font-size: 17px;
  text-align: center;
  padding-top: 17px;
}

.remainingAmountLabel {
  margin-top: 27px;
  margin-left: 30px;
  font-size: 15px;
  color: #00000069;
}

.remainingAmountValue {
  margin-top: 27px;
  margin-right: 32px;
  text-align: right;
  font-size: 15px;
  color: #00000069;
}
.totalAmountValue {
  margin-right: 32px;
  text-align: right;
  font-size: 15px;
  margin-bottom: 22px;
}

.totalAmountLabel {
  margin-left: 30px;
  font-size: 15px;
  margin-bottom: 22px;
}

.paymentSuccessHeaderText {
  font-size: 19px;
  text-align: center;
  padding-top: 17px;
}

.paymentSuccessSubHeaderText {
  font-size: 14px;
  text-align: center;
  color: #00000069;
  margin-bottom: 24px;
}

.radioEmail {
  text-align: right;
  padding-right: 14px;
  padding-top: 17px;
}
.radioSms {
  text-align: left;
  padding-left: 14px;
  padding-top: 17px;
}

.emailIcon {
  position: relative;
  top: -5px;
  right: 10px;
  display: inline-block;
  @supports (-webkit-touch-callout: none) {
    line-height: 10px !important;
  }
}

.emailInputContainer {
  @supports (-webkit-touch-callout: none) {
    line-height: 1px !important;
  }
}
.emailInput {
  background-color: rgb(255, 255, 255);
  border: 1px solid #5e58734d;
  height: 38px;
  border-radius: 5px;
  padding-left: 20px;
  font-family: "Montserrat-Medium";
  padding-right: 20px;
  width: 80%;
  @supports (-webkit-touch-callout: none) {
    line-height: 1px !important;
  }
}

.emailInputTextBox {
  position: relative;
  font-family: "Montserrat-Medium";
  font-size: 15px;

  @supports (-webkit-touch-callout: none) {
    line-height: 1px !important;
  }
}

.phoneNumber {
  width: 100%;
}

.sendMyBillButton {
  width: 90%;
  border-radius: 10px !important;
}
.sendMyBillButtonContainer {
  position: relative;
  text-align: center;
  margin: auto;
  width: 93%;
  margin-top: 16px;
}

.smsInput {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 12px;
  width: 100%;
}

.billSentIconContainer {
  margin-top: 24px;
  margin-bottom: 20px;
  height: 54px;
}

.getBillHeader {
  font-size: 13px;
  text-align: left;
  color: #5e5873;

  word-wrap: break-word;
  padding-left: 8.5px;
}
.getBillSubHeader {
  font-size: 14px;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  color: #000000af;
  margin-bottom: 25px;
}

.emoji {
  margin: 0 5px;
}

.emojiSelected {
  margin-left: 0px;
  margin-right: 0px;
}

.loginFrame {
  border-radius: 5px;
  border: 1px solid #5e587310;
  margin: auto;
  margin-top: 20px;
}

.starImageWrapper {
  margin-top: 13px;
  margin-bottom: 8px;
  text-align: center;
}

.feedBackHeader {
  font-size: 13px;
  text-align: left;
  font-family: "Montserrat-Medium";
  color: #3a3745;
}
.feedBackSubHeader {
  font-size: 12px;
  text-align: center;
  background-color: cadetblue;
}

.feedbackSuggestionContainer {
  margin: auto;
  width: 92% !important;
  display: block;
}

.emojiContainer {
  text-align: center;
  max-width: 300px;
  margin: auto;
  gap: 20px;
  margin-top: 17px;
}

.feedBackOptionHeader {
  text-align: left;
  margin-top: 27px;
}

textarea.feedBackInput::-webkit-input-placeholder {
  font-size: 0.855rem;
  color: #00000069;
}

.feedBackButton {
  // background-color: #e4e4e3 !important;
  margin: 5px !important;
  font-size: 11px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: "Montserrat-Regular" !important;
  text-transform: none !important;
}

.feedBackInputContainer {
  margin: auto;
  width: 100%;
  margin: 11px 0 14px 0;
  overflow: hidden;
}
.feedBackInput {
  background-color: rgb(255, 255, 255);
  border: 1px solid #5e58734d;
  border-radius: 5px;
  height: 83px;
  width: 100%;
  font-family: "Montserrat-Regular" !important;
  padding: 10px 17px;
  font-size: 15px;
}

.feedBackInput:focus {
  outline: none !important;
  border: 1px solid #5e58734d;
  //box-shadow: 0 0 10px #719ECE;
}
.feedBackIconContainer {
  text-align: center;
  margin-top: 23px;
  margin-bottom: 13px;
}

.thumbsUpContianer {
  margin-left: auto;
  margin-right: 18px;
  margin-top: 5px;
  height: 26px;
  width: 26px;
  border-radius: 40px;
  padding-top: 2px;
  text-align: center;
  border: 2px solid #1dc369;
}

.thumbsUpIcon {
  height: 26px;
  width: 26px;
  border-radius: 40px;
  padding: 3px;
  text-align: center;
  border: 2px solid #1dc369;
  color: #1dc369;
}

.headerIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerTextContainer {
  padding-left: 10px;
}

.thankYouTextHeader {
  text-align: left;
  font-family: "Montserrat-Medium" !important;
  color: #5e5873;
  font-size: 13px;
}

.thankYouTextSubHeader {
  text-align: left;
  font-family: "Montserrat-Medium" !important;
  font-size: 12px;
  color: #5e5873b3;
}
.modalSubHeader {
  margin-top: 5px;
  text-align: left;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  color: #5e5873b3;
}

.feedbackContainer {
  margin-bottom: 19px !important;
}

.remainingBillContainer {
  font-family: "Montserrat-Medium";
  display: flex;
  justify-content: space-between;
}

