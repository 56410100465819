@import "./variables.scss";
.tipTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
}

.addTipWarning {
  font-size: 12px !important;
  position: relative;
  text-align: left !important;
  color: red;
  font-family: $font-regular;
  margin-bottom: 10px;
}

.addTipWarningBlack {
  font-size: 12px !important;
  position: relative;
  font-family: $font-regular;
  color: #5e5873;
  text-align: left !important;
  margin-bottom: 10px;
}

.selectTipMiniCard {
  font-size: 13.8px !important;
  text-align: center;
  border: 1px solid #5e58734d;
  margin: 5px;
  padding-top: 5px;
  border-radius: 8px;
  min-height: 54px;
  margin: auto;
  color: #5e5873 !important;
  font-size: 16px !important;
  font-family: $font-regular;
  cursor: pointer;
  
}

.selectTipMiniCardSelected {
  font-size: 16px !important;
  text-align: center;
  border: 1px solid #2621615f;
  color: black !important;
  background: #2621612a;

  padding-top: 5px;
  border-radius: 8px;
  min-height: 54px;
  margin: auto;
  font-family: $font-regular;
  cursor: pointer;
}

.selectTipPercent {
  color: #5e5873b3;
}
.selectTipPercentSelected {
  color: "#00000080" !important;
}

.customTipCard {
  font-size: 13.8px !important;
  text-align: center;
  border: 1px solid #5e58734d;
  padding-top: 5px;
  border-radius: 8px;
  min-height: 54px;
  color: #5e5873b3;
  font-size: 16px;
  font-family: $font-regular;
  position: relative;
  cursor: pointer;
}

.selectCustomTipCard {
  @extend .customTipCard;
  background-color: $primary-color;
  color: #000000;
  border: none;
  cursor: pointer;
}

.tipWatermark {
  font-size: 12px;
  font-family: $font-regular;
  height: 11px !important;
  padding-top: 0 !important;
  line-height: 11px !important;
  @supports (-webkit-touch-callout: none) {
    height: 11px !important;
    line-height: 11px !important;
  }
}

.subTipTag {
  color: #5e5873b3 !important;
  font-size: 10px !important;
  padding: 12px 0 0 0px;
}

.editIc {
  color: "#5E5873";
  font-size: 18px !important;
  margin-top: 3px;
}

.customTipBlur {
  opacity: 0;
  width: 100%;
}

.customTip {
  width: 80%;
  border: 0;
  text-align: center !important;
  margin: auto;
  display: block;
  padding: 1px 0;
  outline: none !important;
  font-size: 16px !important;
  font-family: $font-medium;
  background: none;
  margin-top: 3px;
  margin-bottom: 4px;
  :focus {
    outline: none;
  }
}

