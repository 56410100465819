.logoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.logo {
  object-fit: contain;
  height: auto;
  max-height: 150px;
  max-width: min(60%, 300px);
  border-radius: 20px;
}

@media (min-width: 580px) {
  .logo {
    max-height: 200px;
  }
}
