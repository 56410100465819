.backButton {
  margin-left: 5%;
  height: 50px;
  padding-top: 2%;
}
.backArrow {
  display: inline-block;
  transform: rotate(180deg);
  padding-top: 2px;
}
.backText {
  display: inline-block;
  font-size: 16px;
  font-family: "Manrope";
  padding-top: 5px;
  padding-left: 7px;
}

.label {
  text-align: center;
  font-size: 16px;
}

.logoDim {
  max-width: 150px;
  max-height: 170px;
}

.inputContainer {
  padding-top: 14px;
  margin: auto;
  text-align: center;
}

.image {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.input {
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 32px;
  width: 40%;
}

.backgroundImage {
  max-width: 150px;
  max-height: 170px;
}

.socialIcons {
  display: inline-block;
  text-align: center;
  margin: auto;
  margin-top: 33.38px;
  margin-bottom: 3px;
}

.textHeader {
  font-size: 16px;
  font-family: "Montserrat-Medium";
  padding: 20px 0px;

  color: #3e3e3e;
}

.textdata {
  padding-top: 0.5rem;
  padding-right: 2rem;
  line-height: 1.6;
  font-size: 14px;
}

.address {
  display: flex;
  color: #5e5873;
  font-family: "Montserrat-Medium" !important;
  font-size: 14px;
  margin-top: 15px;
  // font-family: "Brandon Grotesque Medium" !important;
}
.addressCont {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.phoneIC {
  padding-top: 18px;
  display: flex;
  align-items: center;
}
.subHeader {
  text-align: center;
  padding-top: 11px;
  font-family: "Montserrat-Medium";
  color: #5e5873;
  letter-spacing: 0.6px;
}
.hours {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5e5873;
  font-family: "Montserrat-Medium" !important;
  padding-top: 18px;
}

.socialButtonWrapper {
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 25px;
}

.divider {
  color: #5e5873;
  padding-bottom: 15px;
  font-family: "Montserrat-Medium" !important;
}

.line {
  width: 120% !important;
  border-bottom: 1px solid #00000025;
  margin-left: -40px;
}

.line2 {
  width: 140% !important;
  border-bottom: 1px solid #00000025;
  left: -40px;
}

.color {
  color: rgb(7, 11, 219) !important;
  background-color: brown !important;
}
