//colors

$primary-color: #262161;
$border-color: #5e58734d;

$font-black: #000000;
$font-gray-dark: #3a3745;
$font-gray-medium: #5e5873;
$font-gray-light: #5e5873b3;
$font-gray-accent: #5e587380;

// border

$border-radius: 5px;
$border: 1px solid #5e58734d;
$border-bold: 2px solid #5e58734d;

// font

$font-regular: "Montserrat-Regular";
$font-medium: "Montserrat-Medium";
$font-semibold: "Montserrat-SemiBold";
